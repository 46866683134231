.largeScreenSliderVariables {
    --height: 50px;
    --thumbHeight: 50px;
    --thumbWidth: 50px;
    --trackColor: transparent;
    --rangeHeight: 1px;
    --rangeColor: #353A44;
    --thumbColor: #141925;
    --thumbTickHeight: 5px;
    --thumbBorderWidth: 1px;
}

.smallScreenSliderVariables {
    --height: 40px;
    --thumbHeight: 40px;
    --thumbWidth: 40px;
    --trackColor: transparent;
    --rangeHeight: 1px;
    --rangeColor: #353A44;
    --thumbColor: #141925;
    --thumbTickHeight: 5px;
    --thumbBorderWidth: 1px;
}

.hiddenInput {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    pointer-events: none;
    position: absolute;
    height: 0;
    width: 100%;
    outline: none;
    z-index: 3;
}

.hiddenInput::-webkit-slider-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: transparent;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    width: var(--thumbWidth);
    height: var(--thumbHeight);
    margin-top: calc(var(--thumbHeight) + var(--thumbTickHeight)*2);
    pointer-events: all;
    position: relative;
}

.hiddenInput::-moz-range-thumb {
    -webkit-appearance: none;
    -webkit-tap-highlight-color: white;
    border: none;
    border-radius: 2px;
    background-color: transparent;
    cursor: pointer;
    width: var(--thumbWidth);
    height: var(--thumbHeight);
    transform: translateY(calc(var(--thumbHeight)/2 + var(--thumbTickHeight)));
    pointer-events: all;
    position: relative;
}

.slider {
    position: relative;
    width: 100%;
    height: var(--height);
    margin-bottom: var(--thumbTickHeight);
}

.track {
    border-radius: 2px;
    height: var(--height);
    position: absolute;
    background-color: var(--trackColor);
    width: 100%;
    z-index: 1;
    margin-top: var(--thumbTickHeight);
}

.range {
    border-radius: 2px;
    height: var(--height);
    height: var(--rangeHeight);
    top: calc(50% - var(--rangeHeight)/2 + var(--thumbTickHeight));
    position: absolute;
    background-color: var(--rangeColor);
    z-index: 2;
}

.handle {
    position: absolute;
    top: var(--thumbTickHeight);
    will-change: left;
    z-index: 5;
    pointer-events: none;
    display: flex;
    justify-content: center;
    align-items: center;
    text-align: center;
    padding: 2px;
    color: #A8DC00;
    font-size: 0.6rem;
    width: var(--thumbWidth);
    height: var(--thumbHeight);
    word-spacing: 20px;
    border: var(--thumbBorderWidth) solid #353A44;
    border-radius: 2px;
    background-color: var(--thumbColor);
    box-sizing: border-box;
    touch-action: manipulation;
    user-select: none;
}

.handle::after {
    position: absolute;
    top: calc(var(--thumbTickHeight) * -1);
    width: 0;
    height: 0;
    border-right: var(--thumbTickHeight) solid transparent;
    border-bottom: var(--thumbTickHeight) solid #353A44;
    border-left: var(--thumbTickHeight) solid transparent;
    content: '';
}

.calibrationPointLabel {
    --width: 50px;
    --height: 25px;
    --tickWidth: 5px;
    --fontSize: 0.7rem;
    will-change: left;

    top: calc((var(--height) + var(--tickWidth)) * -1);
    width: var(--width);
    height: var(--height);

    position: absolute;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #141925;
    color: #ffc02c;
    font-size: var(--fontSize);
    border: 1px solid #353A44;
    border-radius: 2px;
    box-sizing: border-box;
    cursor: pointer;
}

@media only screen and (max-width: 767px) {
    .calibrationPointLabel {
        --width: 40px;
        --height: 20px;
        --fontSize: 0.6rem;
    }
}

.calibrationPointLabel::before {
    position: absolute;
    bottom: calc(var(--tickWidth) * -1);
    width: 0;
    height: 0;
    border-right: var(--tickWidth) solid transparent;
    border-top: var(--tickWidth) solid #353A44;
    border-left: var(--tickWidth) solid transparent;
    content: '';
}

.calibrationPointLabel[disabled] {
    cursor: inherit;
    pointer-events: none;
}